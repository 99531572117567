<template>
  <div>
    <v-row :style="{ height: '100vh' }" class="ma-0" cols="12">
      <v-col md="4" sm="0" lg="6" class="d-none d-sm-block Leftbackground">
      </v-col>
      <v-col md="8" sm="12" lg="6" align-self="center">
        <v-row align="center" justify="center">
          <v-card variant="flat" class="mx-5 my-5">
            <div class="Login_text mx-5">
              <h1>Welcome to Cannex Please Request for Admin access</h1>
            </div>
            <!-- <v-btn class="button">Login</v-btn> -->

            <a
              href="mailto:support@canex.africa?subject=Request For Admin Role!&body=Would like to access the admin dashboard!"
            >
              <div class="button">Request for admin dashboard</div>
            </a>

            <div class="infoText mt-5">
              By continuing, you agree to the
              <a href="/">Terms of Service</a> <br />and acknowledge you’ve read
              <a href="/">Privacy Policy</a>.
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Options API
export default {
  data: function () {
    return {
      user: this.$auth0.user,
    };
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect({
        appState: {
          target: "/home",
        },
      });
    },
  },
};
</script>

<style scoped>
.Login_text {
  color: #111;
  text-align: center;
  font-style: normal;
}
.infoText {
  color: #666;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.button {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;

  height: 64px;
  margin-top: 5%;

  border-radius: 32px;
  opacity: 0.25;
  background: #111;
}
.button:hover {
  opacity: 1;
}
a {
  text-decoration: none;
}
/* Add your styles here */
.Leftbackground {
  background-image: url("@/assets/pictures/picture.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}
.loginCards {
  background-color: #f5f5f5;
  width: 55%;
  margin-top: 20%;
  margin-left: 20%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 500px) {
  .Leftbackground {
    height: 50%;
  }
}
@media only screen and (min-width: 500px) {
  .Leftbackground {
    height: 50%;
  }
}
@media only screen and (min-width: 1000px) {
  .Leftbackground {
    height: 100%;
  }
}
</style>
