<template>
  <v-progress-linear
    color="pink"
    v-if="$store.state.progressbar.show"
    indeterminate
  ></v-progress-linear>
</template>

<script>
export default {};
</script>
