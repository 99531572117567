import {
  getServiceProviders,
  updateServiceProviders,
} from "@/api/ServiceProvidersApi";

export default {
  async fetchgetServiceProviders({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getServiceProviders(pageNumber);
      const serviceProviders = response.data.content;
      const totalServiceProviders = response.data.totalElements;
      commit("SET_SERVICE_PROVIDER", serviceProviders, totalServiceProviders);
      commit("SET_TOTAL_SERVICE_PROVIDERS", totalServiceProviders);
    } catch {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting Service providers , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
  async updateBusinessKyc(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await updateServiceProviders(payload);
      context.dispatch("fetchgetServiceProviders", context.state.currentPage);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Service provider Kyc Status updated!",
          color: "success",
        },
        { root: true }
      );
    } catch {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Service provider KYC status update failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
