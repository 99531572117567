import { getIndividuals, updateInvidualKyc } from "@/api/InvidualKyc";

export default {
  async fetchindividuals({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getIndividuals(pageNumber);
      const individuals = response.data.content;
      const totalIndividuals = response.data.totalElements;
      commit("SET_INDIVIDUALS", individuals, totalIndividuals);
      commit("SET_TOTAL_INDIVIDUALS", totalIndividuals);
    } catch {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting individuals , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },

  async updateInvidualKyc(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await updateInvidualKyc(payload);
      context.dispatch("fetchindividuals", context.state.currentPage);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Individuals kyc status updated!",
          color: "success",
        },
        { root: true }
      );
    } catch {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Company KYC status update failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
