import { createAuth0 } from "@auth0/auth0-vue";

const client = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AOUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: process.env.VUE_APP_AOUTH0_CALLBACK_URL,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    scope: "openid profile email",
  },
});

export default client;

export const getAccessToken = async () => {
  const accessToken = await client.getAccessTokenSilently();
  return accessToken;
};
