import { getAllCompanies, updateCompanyKyc } from "@/api/companiesApi";

export default {
  async fetchCompanies({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getAllCompanies(pageNumber);
      const companies = response.data.content;
      const totalcompanies = response.data.totalElements;
      commit("SET_COMPANIES", companies, totalcompanies);
      commit("SET_TOTAL_COMPANIES", totalcompanies);
    } catch (e) {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting companies , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },

  async updateBusinessKyc(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await updateCompanyKyc(payload);
      context.dispatch("fetchCompanies", context.state.currentPage);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Company Kyc status updated!",
          color: "success",
        },
        { root: true }
      );
    } catch {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Company KYC status update failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
