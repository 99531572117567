import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import loginView from "@/views/loginView.vue";
import RequestForPermission from "@/views/RequestForPermission.vue";
import OverviewView from "@/views/OverviewView.vue";
import IndividualsKycView from "@/views/IndividualsKycView.vue";
import AllUserKycView from "@/views/AllUserKycView.vue";
import CompanyKyc from "@/views/CompanyKycView.vue";
import SmeBusinessesKycView from "@/views/SmeBusinessesKycView.vue";
import FinancialInstituonsKycView from "@/views/FinancialInstituonsKycView.vue";
import ServiceProviderKyc from "@/views/ServiceProviderKycView.vue";
import EventsView from "@/views/EventsView.vue";
import AttendeesView from "@/views/AttendeesView.vue";
import { authGuard } from "@auth0/auth0-vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: loginView,
  },
  {
    path: "/",
    name: "requestForPermission",
    component: RequestForPermission,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        name: "dashboard",
        component: OverviewView,
      },
      {
        path: "events",
        name: "events",
        component: EventsView,
      },
      {
        path: "attendees",
        name: "attendees",
        component: AttendeesView,
      },
      {
        path: "individuals",
        name: "individualKYC",
        component: IndividualsKycView,
      },
      {
        path: "alluserskyc",
        name: "AllUserskyc",
        component: AllUserKycView,
      },
      {
        path: "companykyc",
        name: "Companykyc",
        component: CompanyKyc,
      },
      {
        path: "smeskyc",
        name: "Smeskyc",
        component: SmeBusinessesKycView,
      },
      {
        path: "financekyc",
        name: "Finacekyc",
        component: FinancialInstituonsKycView,
      },
      {
        path: "serviceProviderKyc",
        name: "ServiceProviderKyc",
        component: ServiceProviderKyc,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
