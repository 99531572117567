import { getAllSmes, updateSmeKyc } from "@/api/smesApi";

export default {
  async fetchSmes({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getAllSmes(pageNumber);
      const smes = response.data.content;
      const totalsmes = response.data.totalElements;
      commit("SET_SMES", smes, totalsmes);
      commit("SET_TOTAL_SMES", totalsmes);
    } catch {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting smes , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
  async updateBusinessKyc(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await updateSmeKyc(payload);
      context.dispatch("fetchSmes", context.state.currentPage);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Kyc Status updated!",
          color: "success",
        },
        { root: true }
      );
    } catch {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "sme KYC status update failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
