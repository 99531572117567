<template>
  <div>
    <v-container>
      <div class="mx-4 mt-6">
        <div class="pageHeader">
          <div class="headerText">Events created list</div>
        </div>
        <v-row>
          <v-col cols="4">
            <v-row>
              <v-col>
                <div class="stats">
                  <div class="stats-text">Total events</div>
                  <div class="stats-number">{{ totalevents }}</div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row class="mt-8 ma-2">
        <v-col style="color: red">
          <EventsTable :events="events" :totalevents="totalevents" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventsTable from "@/components/Events/EventsTable.vue";

export default {
  data() {
    return {};
  },
  components: {
    EventsTable,
  },
  created() {
    this.$store.dispatch("events/fetchEvents", 0);
  },
  computed: {
    events() {
      return this.$store.getters["events/events"];
    },
    totalevents() {
      return this.$store.getters["events/totalevents"];
    },
  },
  methods: {},
};
</script>

<style scoped>
.stats {
  width: 262px;
  height: 112px;
  margin-top: 3%;
  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
}
.stats-text {
  color: var(--black-100, #1c1c1c);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10%;
  padding-top: 7%;
}
.stats-number {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  padding-top: 7%;
  margin-left: 15%;
}
.pageHeader {
  background-image: url(../assets/pictures/events.png);
  background-size: cover;
  width: 100%;
  height: 86px;
  margin-left: 0%;
}
.headerText {
  padding-top: 1.8%;
  margin-left: 2%;
  color: #fff;
  -webkit-text-stroke-width: 0.20000000298023224;
  -webkit-text-stroke-color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
