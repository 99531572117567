import { getAllUsers, updateUserKyc } from "@/api/AllUsersApi";

export default {
  async fetchusers({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getAllUsers(pageNumber);
      const users = response.data.content;
      const totalUsers = response.data.totalElements;
      commit("SET_USERS", users, totalUsers);
      commit("SET_TOTAL_USERS", totalUsers);
    } catch (e) {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting users , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },

  async updateUserKyc(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await updateUserKyc(payload);
      context.dispatch("fetchusers", context.state.currentPage);
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Users Kyc Status updated!",
          color: "success",
        },
        { root: true }
      );
    } catch (e) {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Users Kyc Status update failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
