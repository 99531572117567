<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5"> VerifyIndividual?</v-card-title>
      <v-card-text>Are you sure you want to Verify this creative?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> NO</v-btn>
        <v-btn color="#1AE58E" text @click="updateUserKyc"> YES</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["individual"],
  created() {
    this.dialog = true;
  },
  methods: {
    verifyCreative() {
      this.closeDialoag();
    },
    closeDialoag() {
      this.$emit("close");
    },
    updateUserKyc() {
      // Your logic here when a radio button is checked
      let payload = {
        id: this.individual.id,
        values: {
          status: "verified",
        },
      };
      this.$store.dispatch("allUsers/updateUserKyc", payload);
      this.$emit("close");
    },
  },
};
</script>
