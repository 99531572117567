<template>
  <div>
    <v-container>
      <div class="mx-4 mt-6">
        <div class="pageHeader">
          <div class="headerText">{{ currenteventname }}</div>
          <v-spacer></v-spacer>
          <v-btn
            ripple
            class="mt-5 mr-5"
            style="border-color: white; font-size: 14px; font-weight: bold"
            variant="text"
            color="white"
            rounded="xl"
            border
            @click="viewEvents"
            >View Events
          </v-btn>
        </div>
        <v-row>
          <v-col cols="4">
            <v-row>
              <v-col>
                <div class="stats">
                  <div class="stats-text">Total Attendees</div>
                  <div class="stats-number">{{ totalattendees }}</div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-row class="mt-8 ma-2">
        <v-col>
          <AttendeesTable
            :attendees="attendees"
            :totalattendees="totalattendees"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AttendeesTable from "@/components/Attendees/AttendeesTable.vue";

export default {
  data() {
    return {};
  },
  components: {
    AttendeesTable,
  },
  created() {
    this.$store.dispatch("attendees/fetchAttendeesForAnEvent", 0);
  },
  computed: {
    attendees() {
      return this.$store.getters["attendees/attendees"];
    },
    currenteventname() {
      return this.$store.getters["attendees/currentEventName"];
    },
    totalattendees() {
      return this.$store.getters["attendees/totalattendees"];
    },
    currenteventid() {
      return this.$store.getters["attendees/currentEventId"];
    },
  },
  methods: {
    viewEvents() {
      this.$router.push({ name: "events" });
    },
  },
};
</script>

<style scoped>
.stats {
  width: 262px;
  height: 112px;
  margin-top: 3%;
  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
}
.stats-text {
  color: var(--black-100, #1c1c1c);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10%;
  padding-top: 7%;
}
.stats-number {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  padding-top: 7%;
  margin-left: 15%;
}
.pageHeader {
  background-image: url(../assets/pictures/events.png);
  background-size: cover;
  display: flex;
  width: 100%;
  height: 86px;
  margin-left: 0%;
}
.eventsButton {
  border-radius: 50px;
  border: 2px solid #fff;
  margin-top: 1%;
  margin-left: 80%;
  display: flex;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  height: var(--48, 48px);
  padding: var(--16, 16px) var(--12, 12px);
  justify-content: center;
  align-items: center;
}
.headerText {
  padding-top: 1.8%;
  padding-left: 2%;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
