export default {
  SET_EVENTS(state, events, totalevents) {
    state.events = events;
    state.totalevents = totalevents;
  },
  SET_TOTAL_EVENTS(state, totalevents) {
    state.totalevents = totalevents;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  SET_CURRENT_EVENT_ID(state, currentEventId) {
    state.currentEventId = currentEventId;
  },
  SET_CURRENT_EVENT_NAME(state, currentEventName) {
    state.currentEventName = currentEventName;
  },
};
