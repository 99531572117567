<template>
  <v-data-table
    v-model:page="page"
    :headers="headers"
    :items="users"
    :items-per-page="itemsPerPage"
    @click:row="selectrow"
  >
    <template v-slot:[`item.picture`]="{ item }">
      <v-avatar size="36">
        <v-img :src="item.picture" :alt="item.picture" cover />
      </v-avatar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <AllUsersTableMenu :individual="item" />
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip class="ma-2" :color="getColor(item.status)[1]" label outlined>
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:bottom>
      <div class="text-center pt-2">
        <PaginationTable
          :totalNumberofitems="totalusers"
          :itemsPerPage="itemsPerPage"
          :store="storeName"
          :action="actionName"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import AllUsersTableMenu from "./AllUsersTableMenu.vue";
import PaginationTable from "../shared/paginationTable.vue";
import { format } from "date-fns";

export default {
  name: "IndividualsTable",
  props: {
    users: {
      type: Array,
      required: true,
    },
    totalusers: {
      type: Number,
      required: true,
    },
  },
  components: {
    AllUsersTableMenu,
    PaginationTable,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 25,
    storeName: "allUsers",
    actionName: "fetchusers",
    headers: [
      { title: "Avatar", value: "picture" },
      { title: "Name", value: "name" },
      { title: "Email", value: "email" },
      { title: "Country", value: "country" },
      { title: "City", value: "city" },
      { title: "PhoneNumber", value: "phoneNumber" },
      { title: "Entity", value: "entity.name" },
      { title: "Sector", value: "roles[0].sectorsRoles.sector.name" },
      { title: "Role", value: "roles[0].sectorsRoles.name" },
      { title: "Status", value: "status" },
      { title: "Actions", value: "actions" },
    ],
    loading: true,
    totalItems: 0,
  }),
  filters: {
    niceDate(value) {
      return format(new Date(value), "MMM d");
    },
  },
  methods: {
    getColor(active) {
      if (active === "verified") return ["Active", "#1AE58E"];
      else if (active === "unverified") return ["Inactive", "#EC4A4A"];
      else return "green";
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.desserts.length / this.itemsPerPage);
    },
  },
};
</script>
