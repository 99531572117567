<!-- eslint-disable prettier/prettier -->
<template>
  <v-data-table
    v-model:page="page"
    :headers="headers"
    :items="attendees"
    :items-per-page="itemsPerPage"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <AttendeesTableMenuVue :attendee="item" />
    </template>
    <template v-slot:bottom>
      <div class="footer">
        <PaginationTable
          :totalNumberofitems="totalattendees"
          :itemsPerPage="itemsPerPage"
          :store="storeName"
          :action="actionName"
        />
        <div class="dropdown-buttons">
          <div>
            <div class="text-center filter-button">
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn
                    variant="outlined"
                    v-bind="props"
                    append-icon="mdi-chevron-up"
                  >
                    {{ selectedTitle }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="selectTitle(item.title, item.value)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="text-center generate-csv ma-2">
            <v-btn color="#00AA8C" @click="generateCsv()"> Generate CSV </v-btn>
          </div>
          <div class="text-center download-csv ma-2">
            <v-btn v-bind="props" color="#00AA8C" @click="downloadCSV()"> Download CSV </v-btn>
          </div>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import AttendeesTableMenuVue from "./AttendeesTableMenu.vue";
import PaginationTable from "../shared/paginationTable.vue";
import { format } from "date-fns";
export default {
  name: "EventsTable",
  props: {
    attendees: {
      type: Array,
      required: true,
    },
    totalattendees: {
      type: Number,
      required: true,
    },
  },
  components: {
    AttendeesTableMenuVue,
    PaginationTable,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 25,
    storeName: "events",
    actionName: "fetchEvents",
    selectedTitle: "Last 7 days",
    days: 7,
    headers: [
      { title: "FirstName", value: "attendee.firstName" },
      { title: "LastName", value: "attendee.lastName" },
      { title: "email", value: "attendee.emailAddress" },
      { title: "phoneNumber", value: "attendee.phoneNumber" },
      { title: "Nationality", value: "attendee.nationality" },
      { title: "PassPortNumber", value: "attendee.passportNumber" },
      {
        title: "PassportCountryOfIssue",
        value: "attendee.passportCountryOfIssue",
      },
      {
        title: "passportExpirationDate",
        value: "attendee.passportExpirationDate",
      },
      { title: "Company", value: "attendee.company" },
      { title: "Jobtitle", value: "attendee.jobTitle" },
      { title: "Workaddress", value: "attendee.workAddress1" },
      { title: "Actions", value: "actions" },
    ],
    items: [
      { title: "Last 7 days", value: "days=7" },
      { title: "Last 30 days", value: "days=30" },
      { title: "Last 3 months", value: "days=90" },
      { title: "All Time", value: null },
    ],
    loading: true,
    totalItems: 0,
  }),
  filters: {
    niceDate(value) {
      return format(new Date(value), "MMM d");
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    selectedOption(newValue, oldValue) {
      this.selectDays(newValue);
    },
  },
  methods: {
    selectTitle(title, value) {
      this.selectedTitle = title;
      this.days = value;
    },
    generateCsv() {
      this.$store.dispatch("attendees/generateCsv", this.days);
    },
    downloadCSV() {
      let csv = this.attendeesCsv;
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = url;
      const eventName = this.attendees[0].event.name;
      const fileName =
        eventName + "_" + new Date().toLocaleDateString() + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    selectDays(selectedOption) {
      switch (selectedOption) {
        case "Last 7 days":
          this.days = 7;
          break;
        case "Last 30 days":
          this.days = 30;
          break;
        case "Last 3 months":
          this.days = 90;
          break;
        case "All Time":
          this.days = 0;
          break;
        default:
          this.days = 0;
      }
    },
  },
  computed: {
    attendeesCsv() {
      return this.$store.getters["attendees/attendeesCsv"];
    },
  },
};
</script>

<style scoped>
@media (max-width: 375px) {
  .dropdown-buttons {
    margin-top: 0.5%;
    width: 100%;
  }
  .download-csv {
    border-radius: 50px;
  }
}
@media (min-width: 720px) {
  .footer {
    display: flex;
  }
  .dropdown {
    display: flex;
  }
  .dropdown-buttons {
    display: flex;
    margin-top: 0.5%;
    margin-left: 5%;
    width: 100%;
  }
  .filter-button {
    margin-top: 5%;
    margin-left: 5%;
  }
  .generate-csv {
    margin-left: 5%;
  }
  .download-csv {
    margin-left: 5%;
    border-radius: 50px;
  }
}
@media (min-width: 1024px) {
  .footer {
    display: flex;
  }
  .dropdown {
    display: flex;
  }
  .dropdown-buttons {
    display: flex;
    margin-top: 0.5%;
    margin-left: 40%;
    width: 100%;
  }
  .filter-button {
    margin-top: 5%;
    margin-left: 5%;
  }
  .generate-csv {
    margin-left: 5%;
  }
  .download-csv {
    margin-left: 5%;
    border-radius: 50px;
  }
}
@media (min-width: 1500px) {
  .footer {
    display: flex;
  }
  .dropdown {
    display: flex;
  }
  .dropdown-buttons {
    display: flex;
    margin-top: 0.5%;
    margin-left: 60%;
    width: 100%;
  }
  .filter-button {
    margin-top: 5%;
    margin-left: 5%;
  }
  .generate-csv {
    margin-left: 5%;
  }
  .download-csv {
    margin-left: 5%;
    border-radius: 50px;
  }
}
</style>
