import { getOverviewUrls } from "@/api/overviewUrls";

export default {
  async fetchurl({ commit }) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const payload = {
        resource: {
          dashboard: parseInt(process.env.VUE_APP_METABASE_DASHBOARD, 10),
        },
        params: {},
      };
      const response = await getOverviewUrls(payload);
      const url = response.data;
      commit("SET_CURRENT_URL", url);
    } catch (e) {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getching dashboard , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
