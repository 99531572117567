<template>
  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card v-if="serviceProvider" class="ma-5 pa-5" width="700">
        <v-card-title>Personal details</v-card-title>
        <v-divider></v-divider>

        <v-row class="mt-4">
          <title-sub-title
            title="User names"
            :subtitle="serviceProvider.user.name"
          ></title-sub-title>
          <title-sub-title
            title="Email"
            :subtitle="serviceProvider.user.email"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Country"
            :subtitle="serviceProvider.user.country"
          ></title-sub-title>
          <title-sub-title
            title="City"
            :subtitle="serviceProvider.user.city"
          ></title-sub-title>
        </v-row>

        <p class="ml-5 mt-5">Sectors</p>
        <v-row class="mt-4">
          <v-col>
            <div v-if="serviceProvider.user.sectors.length > 0">
              <v-row class="ml-5">
                <v-list-item-title
                  v-for="(item, index) in serviceProvider.user.sectors"
                  :key="index"
                  class="textlike mr-5"
                >
                  {{ item.sector.name }}
                </v-list-item-title>
              </v-row>
            </div>
            <div v-else>
              <v-list-item>
                <v-list-item-title class="textlike">
                  not provided
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-col>
        </v-row>

        <p class="ml-5 mt-5">Roles</p>
        <v-row class="mt-4">
          <v-col>
            <div v-if="serviceProvider.user.roles.length > 0">
              <v-row class="ml-5">
                <v-list-item-title
                  v-for="(item, index) in serviceProvider.user.roles"
                  :key="index"
                  class="textlike mr-5"
                >
                  {{ item.sectorsRoles.name }}
                </v-list-item-title>
              </v-row>
            </div>
            <div v-else>
              <v-list-item>
                <v-list-item-title class="textlike">
                  not provided
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-col>
        </v-row>

        <v-card-title>Business details</v-card-title>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <title-sub-title
            title="Name of business"
            :subtitle="serviceProvider.name"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Business Bio"
            :subtitle="serviceProvider.description"
          ></title-sub-title>
        </v-row>
        <p class="ml-5 mt-5 mb-5">Languages</p>
        <v-row>
          <v-col>
            <div v-if="serviceProvider.languages.length > 0">
              <v-row class="ml-5">
                <v-list-item-title
                  v-for="(item, index) in serviceProvider.languages"
                  :key="index"
                  class="textlike mr-5"
                >
                  {{ item.name }}
                </v-list-item-title>
              </v-row>
            </div>
            <div v-else>
              <v-list-item>
                <v-list-item-title class="textlike">
                  not provided
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
        <p class="ml-5 mt-5">Where is your business located?</p>
        <v-row>
          <title-sub-title
            title="Country"
            :subtitle="serviceProvider.headquarters.name"
          ></title-sub-title>
          <title-sub-title
            title="Country of residence"
            :subtitle="serviceProvider.headquarters.name"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Phone number"
            :subtitle="serviceProvider.phoneNumber"
          ></title-sub-title>
          <title-sub-title
            title="Email address"
            :subtitle="serviceProvider.email"
          ></title-sub-title>
        </v-row>
        <p class="ml-5 mt-5 mb-5">Interests</p>
        <v-row>
          <v-col>
            <div v-if="serviceProvider.interests.length > 0">
              <v-row class="ml-5">
                <v-list-item-title
                  v-for="(item, index) in serviceProvider.interests"
                  :key="index"
                  class="textlike mr-5"
                >
                  {{ item.name }}
                </v-list-item-title>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-title class="mt-8">Uploaded documents</v-card-title>
        <v-divider></v-divider>
        <v-row class="my-4">
          <v-chip
            v-if="serviceProvider.registrationDocument"
            class="ma-5 pa-5"
            label
            text-color="#01244A"
            @click="openLink(serviceProvider.registrationDocument)"
          >
            <v-icon class="mr-3" left> mdi-id-card </v-icon>
            Business Registration Document
          </v-chip>
          <div v-else>
            <v-list-item>
              <v-list-item-title class="textlike">
                Business registration document is not provided
              </v-list-item-title>
            </v-list-item>
          </div>
          <v-chip
            v-if="serviceProvider.license"
            class="ma-5 pa-5"
            label
            text-color="#01244A"
            @click="openLink(serviceProvider.license)"
          >
            <v-icon class="mr-3" left> mdi-id-card </v-icon>
            license
          </v-chip>
          <div v-else>
            <v-list-item>
              <v-list-item-title class="textlike">
                License not provided
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="mt-5">
            <v-col>
              <v-btn
                width="100%"
                class="me-2 p-5 text-none"
                color="#00AA8C"
                rounded
                prepend-icon="mdi-check"
                variant="flat"
                @click="updateBusinessKyc('verified')"
              >
                Verify
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                width="100%"
                class="text-none"
                prepend-icon="mdi-receipt-text-minus"
                variant="text"
                border
                rounded
                @click="updateBusinessKyc('unverified')"
              >
                Reject
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                @click="$emit('close')"
                width="100%"
                rounded
                border
                prepend-icon="mdi-close"
                dark
                text
              >
                Close</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TitleSubTitle from "@/components/shared/TitleSubTitle.vue";
export default {
  components: { TitleSubTitle },
  props: ["serviceProvider"],
  created() {
    this.dialog = true;
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    updateBusinessKyc(status) {
      let payload = {
        id: this.serviceProvider.id,
        values: {
          status: status,
        },
      };
      this.$store.dispatch("serviceProviders/updateBusinessKyc", payload);
      this.$emit("close");
    },
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style></style>
