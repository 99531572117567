<template>
  <v-app>
    <v-app-bar flat>
      <div
        style="margin-top: 1%; margin-left: 1%"
        class="hidden-xs hidden-sm hidden-md"
      >
        <img src="../assets/pictures/logo.png" alt="logo" class="image" />
      </div>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="hidden-lg hidden-xl hidden-xxl"
      ></v-app-bar-nav-icon>
      <v-app-bar-title
        class="d-none d-md-flex d-xl-none"
        style="
          margin-top: 1%;
          margin-left: 5%;
          color: var(--Dark-1-Dark, #202020);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
        "
        >Hi, CANEX Admin!</v-app-bar-title
      >
      <SearchBar />
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon style="margin-right: 2%">mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-btn icon style="margin-right: 2%">
        <v-icon>mdi-bell-badge</v-icon>
      </v-btn>
      <v-avatar
        :image="user.picture"
        size="53"
        style="margin-right: 3%"
      ></v-avatar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" class="navigation-drawer">
      <v-card
        density="compact"
        :prepend-avatar="user.picture"
        size="53"
        :subtitle="user['https://canex.com/roles']"
        :title="user.name"
        variant="text"
        style="
          margin-left: 2%;
          margin-top: 5%;
          color: #202020;
          font-size: medium;
        "
      >
      </v-card>
      <!-- <v-divider></v-divider> -->
      <v-list dense>
        <template v-for="(item, index) in items">
          <template v-if="!item.children">
            <v-list-item :key="index" :to="item.to" link>
              <v-list-item-icon style="display: flex">
                <v-icon>{{ item.icon }}</v-icon>
                <v-list-item-title style="margin-left: 3%">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <template v-else>
            <v-menu :key="index">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-icon style="display: flex">
                    <v-icon>{{ item.icon }}</v-icon>
                    <v-list-item-title style="margin-left: 3%">{{
                      item.title
                    }}</v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
                <v-list>
                  <v-list-item
                    v-for="(child, childIndex) in item.children"
                    :key="childIndex"
                    :to="child.to"
                    link
                    dense
                    style="margin-left: 12%"
                  >
                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </template>
              <v-list>
                <v-list-item
                  v-for="(child, childIndex) in item.children"
                  :key="childIndex"
                  :to="child.to"
                  link
                >
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-list>
      <template v-slot:append>
        <v-list flat>
          <v-list-item-group>
            <div style="display: flex">
              <v-list-item @click="logout">
                <v-list-item-icon style="display: flex">
                  <v-icon>mdi-logout-variant</v-icon>
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item-icon>
                <v-list-item-content> </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SearchBar from "@/components/shared/SearchBar.vue";

export default {
  name: "HomeView",
  components: {
    SearchBar,
  },
  data: function () {
    return {
      user: this.$auth0.user,
      drawer: null,
      roleurl: process.env.VUE_APP_ROLE,
      items: [
        {
          title: "Overview",
          icon: "mdi-view-dashboard",
          to: "/home",
          children: null,
        },
        {
          title: "Events",
          icon: "mdi-domain",
          to: "/home/events",
        },
        {
          title: "KYC",
          icon: "mdi-briefcase-check",
          to: "/home/kyc",
          children: [
            {
              title: "All",
              to: "/home/alluserskyc",
            },
            {
              title: "Individuals",
              to: "/home/individuals",
            },
            {
              title: "SMEs",
              to: "/home/smeskyc",
            },
            {
              title: "Financial Institutions",
              to: "/home/financekyc",
            },
            {
              title: "Companies",
              to: "/home/companykyc",
            },
            {
              title: "Service Providers",
              to: "/home/serviceProviderKyc",
            },
          ],
        },
      ],
    };
  },
  async beforeMount() {
    let roles = this.$auth0.user.value[`${this.roleurl.toString()}`];
    let isAdminPresent = roles.some(
      (role) => role === "Super Admin" || role === "Admin"
    );
    console.log(this.$auth0.user);
    if (isAdminPresent) {
      this.$store.dispatch("storeUserData", this.$auth0.user);
      const token = await this.$auth0.getAccessTokenSilently();
      this.$store.dispatch("storeUserToken", token);
    } else {
      this.$router.push({ name: "requestForPermission" });
    }
  },
  methods: {
    logout() {
      this.$auth0.logout();
    },
  },
};
</script>
<style scoped>
.navigation-drawer {
  color: #9da4b4;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border: 0;
  line-height: normal;
}
.home {
  align-items: center;
}
.v-card-title {
  font-size: medium !important;
}
</style>
