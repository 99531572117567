<template>
  <v-pagination
    v-model="currentPage"
    :length="length"
    :total-visible="4"
  ></v-pagination>
</template>

<script>
export default {
  props: ["store", "totalNumberofitems", "itemsPerPage", "action"],
  data() {
    return {
      currentPage: 1,
      length: null,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    currentPage(newVal, oldVal) {
      this.paginatePage(newVal);
    },
  },
  created() {
    this.Getlength();
  },
  computed: {
    // eslint-disable-next-line no-unused-vars
    StoredcurrentPage: {
      get() {
        return this.$store.state[this.store].currentPage + 1;
      },
      set(value) {
        this.$store.commit(this.store + "/SET_CURRENT_PAGE", value - 1);
      },
    },
  },
  methods: {
    Getlength() {
      const getLength = Math.ceil(this.totalNumberofitems / this.itemsPerPage);
      this.length = getLength;
    },
    paginatePage(page) {
      this.$store.dispatch(this.store + "/" + this.action, page - 1);
      this.$store.commit(
        this.store + "/SET_CURRENT_PAGE",
        this.currentPage - 1
      );
    },
  },
};
</script>
