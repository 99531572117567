import axios from "axios";
import { getAccessToken } from "@/plugins/auth";

const instance = axios.create({
  baseURL: process.env.VUE_APP_WEB_SERVER_URL,
});

instance.interceptors.request.use(async (config) => {
  const accessToken = await getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default instance;
