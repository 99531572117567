import { createStore } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import individualModule from "./modules/individuals/index";
import allUsersModule from "./modules/AllUsers/index";
import smesModule from "./modules/smes/index";
import financialInstitutions from "./modules/financialInstitutions/index";
import companies from "./modules/company";
import ServiceProviders from "./modules/Service-providers";
import Events from "./modules/Events/index";
import Attendees from "./modules/Attendees/index";
import Overview from "./modules/Overview/index";

export default createStore({
  modules: {
    individuals: individualModule,
    allUsers: allUsersModule,
    smesBusiness: smesModule,
    financialInstitutions: financialInstitutions,
    companies: companies,
    serviceProviders: ServiceProviders,
    events: Events,
    attendees: Attendees,
    overview: Overview,
  },
  state: {
    user: null,
    token: null,
    userToken: null,
    userAuth0: this,
    progressbar: {
      show: false,
    },
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  },
  getters: {},
  mutations,
  actions,
});
