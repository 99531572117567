import {
  getFinacialInstituons,
  updateFinacialInstituonsKyc,
} from "@/api/FinancialInstituonsApi";

export default {
  async fetchFinacialInstituons({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getFinacialInstituons(pageNumber);
      const finacialInstituons = response.data.content;
      const totalfinancialInstituions = response.data.totalElements;
      commit(
        "SET_FINANCIAL_INSTITITONS",
        finacialInstituons,
        totalfinancialInstituions
      );
      commit("SET_TOTAL_FINANCIAL_INSTITITONS", totalfinancialInstituions);
      commit("HIDE_PROGRESSBAR", false, { root: true });
    } catch {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting financial institutions , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
  async updateBusinessKyc(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await updateFinacialInstituonsKyc(payload);
      context.dispatch("fetchFinacialInstituons", context.state.currentPage);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Financial Instituion Kyc status updated!",
          color: "success",
        },
        { root: true }
      );
    } catch {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Financial Instituion Kyc update failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
