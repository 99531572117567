<template>
  <v-col>
    <v-list lines="one" v-if="subtitle">
      <v-list-item>
        <v-list-item-subtitle class="pb-2"> {{ title }} </v-list-item-subtitle>
        <v-list-item-title class="textlike">
          {{ subtitle }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list lines="one" v-else>
      <v-list-item>
        <v-list-item-subtitle class="pb-2"> {{ title }} </v-list-item-subtitle>
        <v-list-item-title class="textlike"> not provided </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-col>
</template>

<script>
export default {
  props: ["title", "subtitle"],
};
</script>
<style>
.textlike {
  border-radius: var(--12, 12px) !important;
  border: 1px solid rgba(102, 102, 102, 0.35) !important;
  padding: 10px !important;
}
.title {
  color: #333;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.v-list-item-title {
  white-space: break-spaces !important;
}
</style>
