export default {
  SET_FINANCIAL_INSTITITONS(
    state,
    financialInstitutons,
    totalfinancialInstitutons
  ) {
    state.financialInstitutons = financialInstitutons;
    state.totalfinancialInstitutons = totalfinancialInstitutons;
  },
  SET_TOTAL_FINANCIAL_INSTITITONS(state, totalfinancialInstitutons) {
    state.totalfinancialInstitutons = totalfinancialInstitutons;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
};
