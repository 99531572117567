export default {
  financialInstitutions(state) {
    return state.financialInstitutons;
  },
  totalfinancialInstituions(state) {
    return state.totalfinancialInstitutons;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};
