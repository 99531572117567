export default {
  SET_USERS(state, users, totalUsers) {
    state.users = users;
    state.totalUsers = totalUsers;
  },
  SET_TOTAL_USERS(state, totalUsers) {
    state.totalUsers = totalUsers;
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
};
