import { getAllEvents, deleteEvent } from "@/api/EventsApi";

export default {
  async fetchEvents({ commit }, pageNumber) {
    commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      const response = await getAllEvents(pageNumber);
      const events = response.data.content;
      const totalevents = response.data.totalElements;
      commit("SET_EVENTS", events, totalevents);
      commit("SET_TOTAL_EVENTS", totalevents);
    } catch (e) {
      commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting Events , try again !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },

  async softDeleteEvent(context, payload) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    try {
      await deleteEvent(payload);
      context.dispatch("fetchEvents", context.state.currentPage);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Event deleted!",
          color: "success",
        },
        { root: true }
      );
    } catch {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Event deletion failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
