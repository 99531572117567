<template>
  <div class="d-flex justify-space-around">
    <v-menu center left>
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" v-on="on" flat="true">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(index)"
        >
          <v-list-item-icon style="display: flex">
            <!-- <v-icon :color="item.color">{{ item.icon }}</v-icon> -->
            <v-list-item-title style="margin-left: 24px">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <ViewEvent
      :event="event"
      @close="dialogs.view = false"
      v-if="dialogs.view"
    />
  </div>
</template>

<script>
import ViewEvent from "./dialogs/ViewEvent.vue";
export default {
  components: {
    ViewEvent,
  },
  props: ["event"],
  setup() {},
  data: () => ({
    dialogs: {
      view: false,
      verify: false,
      kyc: false,
    },
    items: [
      {
        title: "Preview",
        icon: "mdi-eye",
        color: "#142D3F",
        click() {
          this.dialogs.view = true;
        },
      },
      {
        title: "Delete",
        icon: "mdi-eye",
        color: "#142D3F",
        click() {
          this.softDeleteEvent();
        },
      },
      {
        title: "Request more info",
        icon: "mdi-shield-check",
        color: "#142D3F",
        click() {
          this.dialogs.view = true;
        },
      },
    ],
  }),
  methods: {
    handleClick(index) {
      this.items[index].click.call(this);
    },
    softDeleteEvent() {
      this.$store.dispatch("events/softDeleteEvent", this.event.id);
    },
  },
};
</script>
