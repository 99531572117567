export default {
  SET_ATTENDEES(state, attendees, totalattendees) {
    state.attendees = attendees;
    state.totalattendees = totalattendees;
  },
  SET_TOTAL_ATTENDEES(state, totalattendees) {
    state.totalattendees = totalattendees;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  SET_CURRENT_EVENT(state, currentEventId) {
    state.currentEventId = currentEventId;
  },
  ATTENDEES_CSV(state, csv) {
    state.attendeesCsv = csv;
  },
};
