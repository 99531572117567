export default {
  SET_COMPANIES(state, companies, totalcompanies) {
    state.companies = companies;
    state.totalcompanies = totalcompanies;
  },
  SET_TOTAL_COMPANIES(state, totalcompanies) {
    state.totalcompanies = totalcompanies;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
};
