export default {
  SET_SERVICE_PROVIDER(state, serviceProvider, totalserviceProvider) {
    state.serviceProvider = serviceProvider;
    state.totalserviceProvider = totalserviceProvider;
  },
  SET_TOTAL_SERVICE_PROVIDERS(state, totalserviceProvider) {
    state.totalserviceProvider = totalserviceProvider;
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
};
