export default {
  smes(state) {
    return state.smes;
  },
  totalsmes(state) {
    return state.totalsmes;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};
