import axios from "./config";

export function getFinacialInstituons(pageNumber) {
  return axios.get(
    `/financial-institutions?size=25&page=${pageNumber}&filter=individual`
  );
}

export function updateFinacialInstituonsKyc(data) {
  return axios.patch(`/business/${data.id}/status`, data.values);
}
