<template>
  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card v-if="attendee" class="ma-5 pa-5" width="700">
        <v-card-title>Attendee details</v-card-title>
        <v-divider></v-divider>

        <v-row class="mt-4">
          <title-sub-title
            title="Title"
            :subtitle="attendee.attendee.title.name"
          ></title-sub-title>
          <title-sub-title
            title="First name"
            :subtitle="attendee.attendee.lastName"
          ></title-sub-title>
          <title-sub-title
            title="Last name"
            :subtitle="attendee.attendee.lastName"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="email"
            :subtitle="attendee.attendee.title.emailAddress"
          ></title-sub-title>
          <title-sub-title
            title="Mobile number"
            :subtitle="attendee.attendee.phoneNumber"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Passport number"
            :subtitle="attendee.attendee.passportNumber"
          ></title-sub-title>
          <title-sub-title
            title="Country of issue"
            :subtitle="attendee.attendee.passportCountryOfIssue"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Date of issue of passport"
            :subtitle="attendee.attendee.passportIssueDate"
          ></title-sub-title>
          <title-sub-title
            title="Date of expiry of passport"
            :subtitle="attendee.attendee.passportExpirationDate"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Date of issue of passport"
            :subtitle="attendee.attendee.passportIssueDate"
          ></title-sub-title>
          <title-sub-title
            title="Date of expiry of passport"
            :subtitle="attendee.attendee.passportExpirationDate"
          ></title-sub-title>
        </v-row>
        <v-row class="my-4">
          <v-chip
            v-if="attendee.attendee.passportBioPage"
            class="ma-5 pa-5"
            label
            text-color="#01244A"
            @click="openLink(attendee.attendee.passportBioPage)"
          >
            <v-icon class="mr-3" left> mdi-file-document-outline </v-icon>
            Passport Bio Page
          </v-chip>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Emergency contact name"
            :subtitle="attendee.attendee.emergencyContactName"
          ></title-sub-title>
          <title-sub-title
            title="Emergency contact number"
            :subtitle="attendee.attendee.emergencyContactNumber"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Emergency contact relationship"
            :subtitle="attendee.attendee.emergencyContactName"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Company"
            :subtitle="attendee.attendee.emergencyContactName"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Job title"
            :subtitle="attendee.attendee.jobTitle"
          ></title-sub-title>
          <title-sub-title
            title="Work address 1"
            :subtitle="attendee.attendee.workAddress1"
          ></title-sub-title>
          <title-sub-title
            title="Work address 2"
            :subtitle="attendee.attendee.workAddress2"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Work City*"
            :subtitle="attendee.attendee.workCity"
          ></title-sub-title>
          <title-sub-title
            title="Work State/Province"
            :subtitle="attendee.attendee.workProvince"
          ></title-sub-title>
          <title-sub-title
            title="Work ZIP/Postal Code"
            :subtitle="attendee.attendee.workPostalCode"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Work Country*"
            :subtitle="attendee.attendee.workCity"
          ></title-sub-title>
          <title-sub-title
            title="Work Phone*"
            :subtitle="attendee.attendee.workPhoneNumber"
          ></title-sub-title>
        </v-row>
        <v-row class="mt-4">
          <title-sub-title
            title="Registration Type*"
            :subtitle="attendee.attendee.registrationType"
          ></title-sub-title>
        </v-row>
        <v-card-actions>
          <v-row class="mt-5">
            <v-col>
              <v-btn
                width="100%"
                class="text-none"
                variant="text"
                color="grey"
                border
                rounded
                @click="$emit('close')"
              >
                Request more info
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                @click="$emit('close')"
                width="100%"
                rounded
                border
                prepend-icon="mdi-close"
                dark
                text
              >
                Close</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TitleSubTitle from "@/components/shared/TitleSubTitle.vue";
export default {
  components: { TitleSubTitle },
  props: ["attendee"],
  created() {
    this.dialog = true;
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {},
};
</script>
<style></style>
