<template>
  <v-data-table
    v-model:page="page"
    :headers="headers"
    :items="smes"
    :items-per-page="itemsPerPage"
    @click:row="selectRow"
  >
    <template v-slot:[`item.logo`]="{ item }">
      <v-avatar size="36">
        <v-img v-if="item.logo" :src="item.logo" :alt="item.logo" cover />
        <v-img v-else :src="item.user.picture" cover />
      </v-avatar>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip class="ma-2" :color="getColor(item.status)[1]" label outlined>
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <smesTableMenu :sme="item" />
    </template>
    <template v-slot:bottom>
      <div class="text-center pt-2">
        <PaginationTable
          :totalNumberofitems="totalsmes"
          :itemsPerPage="itemsPerPage"
          :store="storeName"
          :action="actionName"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
import smesTableMenu from "./smeTableMenu.vue";
import PaginationTable from "../shared/paginationTable.vue";
import { format } from "date-fns";

export default {
  name: "smesTable",
  props: {
    smes: {
      type: Array,
      required: true,
    },
    totalsmes: {
      type: Number,
      required: true,
    },
  },
  components: {
    smesTableMenu,
    PaginationTable,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 25,
    storeName: "smeBusinesses",
    actionName: "fetchSmes",
    headers: [
      { title: "Avatar", value: "logo" },
      { title: "Name", value: "name" },
      { title: "Email", value: "emailAddress" },
      { title: "Country", value: "country.name" },
      { title: "City", value: "city" },
      { title: "PhoneNumber", value: "phoneNumber" },
      { title: "BusinesSize", value: "size" },
      { title: "Owners Name", value: "ownerFirstName" },
      { title: "Operating Countries", value: "operatingCountries" },
      { title: "Status", value: "status" },
      { title: "Actions", value: "actions" },
    ],
    loading: true,
    totalItems: 0,
  }),
  filters: {
    niceDate(value) {
      return format(new Date(value), "MMM d");
    },
  },
  methods: {
    getColor(active) {
      if (active === "verified") return ["Active", "#1AE58E"];
      else if (active === "unverified") return ["Inactive", "#EC4A4A"];
      else return "green";
    },
  },
  computed: {},
};
</script>
