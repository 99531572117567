<template>
  <v-app>
    <div>
      <div class="progress-container">
        <progress-linear-vue />
      </div>
      <router-view />
      <v-container>
        <SnackBarVue />
      </v-container>
    </div>
  </v-app>
</template>
<script>
import ProgressLinearVue from "./components/shared/ProgressLinear.vue";
import SnackBarVue from "./components/shared/SnackBar.vue";

export default {
  computed: {},
  name: "App",
  components: {
    ProgressLinearVue,
    SnackBarVue,
  },
};
</script>

<style>
@import "./assets/css/global.css";
.progress-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.v-table {
  font-family: "Poppins" !important;
}
* {
  font-family: "Poppins" !important;
}
</style>
