<template>
  <div class="iframe-container">
    <iframe :src="url" frameborder="0" allowtransparency></iframe>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("overview/fetchurl");
  },
  computed: {
    url() {
      return this.$store.getters["overview/url"];
    },
  },
};
</script>

<style>
.iframe-container {
  height: 100vh;
  background-color: #ffffff;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
}
</style>
