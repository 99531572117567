export default {
  individuals(state) {
    return state.individuals;
  },
  individualsObj(state) {
    return state.individualsObj;
  },
  totalIndividuals(state) {
    return state.totalIndividuals;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};
