<template>
  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card v-if="event" class="ma-5 pa-5" width="700">
        <v-card-title>Event details</v-card-title>
        <v-divider></v-divider>

        <v-row class="mt-4">
          <title-sub-title
            title="Event name"
            :subtitle="event.name"
          ></title-sub-title>
          <title-sub-title
            title="Venue"
            :subtitle="event.venue"
          ></title-sub-title>
          <title-sub-title
            title="Country"
            :subtitle="event.country"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Price"
            :subtitle="event.price"
          ></title-sub-title>
          <title-sub-title
            title="Currency"
            :subtitle="event.currency.currency"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="description"
            :subtitle="event.description"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Venue"
            :subtitle="event.venue"
          ></title-sub-title>
          <title-sub-title
            title="Start date"
            :subtitle="event.startDate"
          ></title-sub-title>
          <title-sub-title
            title="Start time"
            :subtitle="event.startTime"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Location"
            :subtitle="event.location"
          ></title-sub-title>
          <title-sub-title
            title="End date"
            :subtitle="event.endDate"
          ></title-sub-title>
          <title-sub-title
            title="End time"
            :subtitle="event.endTime"
          ></title-sub-title>
        </v-row>

        <v-card-title>Organaiser details</v-card-title>
        <v-divider></v-divider>
        <v-row>
          <title-sub-title
            title="Title"
            :subtitle="event.organizer.title.name"
          ></title-sub-title>
          <title-sub-title
            title="name"
            :subtitle="event.organizer.name"
          ></title-sub-title>
          <title-sub-title
            title="Middle name"
            :subtitle="event.organizer.lastName"
          ></title-sub-title>
          <title-sub-title
            title="Last name"
            :subtitle="event.organizer.middleName"
          ></title-sub-title>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="mt-5">
            <v-col>
              <v-btn
                width="100%"
                class="text-none"
                prepend-icon="mdi-receipt-text-minus"
                variant="text"
                color="red"
                border
                rounded
                @click="$emit('close')"
              >
                Delete
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                @click="$emit('close')"
                width="100%"
                rounded
                border
                prepend-icon="mdi-close"
                dark
                text
              >
                Close</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TitleSubTitle from "@/components/shared/TitleSubTitle.vue";
export default {
  components: { TitleSubTitle },
  props: ["event"],
  created() {
    this.dialog = true;
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {},
};
</script>
<style></style>
