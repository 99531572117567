export default {
  SET_SMES(state, smes, totalsmes) {
    state.smes = smes;
    state.totalsmes = totalsmes;
  },
  SET_TOTAL_SMES(state, totalsmes) {
    state.totalsmes = totalsmes;
  },
  SET_CURRENT_PAGE(state, currentPage) {
    state.currentPage = currentPage;
  },
};
