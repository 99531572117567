export default {
  attendees(state) {
    return state.attendees;
  },
  totalattendees(state) {
    return state.totalattendees;
  },
  currentEventId(state) {
    return state.currentEventId;
  },
  currentEventName(state) {
    return state.currentEventName;
  },
  attendeesCsv(state) {
    return state.attendeesCsv;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};
