<template>
  <div class="d-flex justify-space-around">
    <v-menu center left>
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" v-on="on" flat="true">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleClick(index)"
        >
          <v-list-item-icon style="display: flex">
            <!-- <v-icon :color="item.color">{{ item.icon }}</v-icon> -->
            <v-list-item-title style="margin-left: 24px">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <ViewIndividual
      :individual="individual"
      @close="dialogs.view = false"
      v-if="dialogs.view"
    />
    <VerifyIndividual
      :individual="individual"
      @close="dialogs.verify = false"
      v-if="dialogs.verify"
    />
  </div>
</template>

<script>
import ViewIndividual from "./dialogs/ViewIndividual.vue";
import VerifyIndividual from "./dialogs/VerifyIndividual.vue";
export default {
  components: {
    ViewIndividual,
    VerifyIndividual,
  },
  props: ["individual"],
  setup() {},
  data: () => ({
    dialogs: {
      view: false,
      verify: false,
    },
    items: [
      {
        title: "Preview",
        icon: "mdi-eye",
        color: "#142D3F",
        click() {
          this.dialogs.view = true;
        },
      },
      {
        title: "Approve",
        icon: "mdi-shield-check",
        color: "#142D3F",
        click() {
          this.updateUserKyc("verified");
        },
      },
      {
        title: "Decline",
        icon: "mdi-eye",
        color: "#142D3F",
        click() {
          this.updateUserKyc("unverified");
        },
      },
      {
        title: "Request more info",
        icon: "mdi-shield-check",
        color: "#142D3F",
        click() {
          this.dialogs.view = true;
        },
      },
    ],
  }),
  methods: {
    handleClick(index) {
      this.items[index].click.call(this);
    },
    updateUserKyc(status) {
      // Your logic here when a radio button is checked
      let payload = {
        id: this.individual.id,
        values: {
          status: status,
        },
      };
      this.$store.dispatch("individuals/updateInvidualKyc", payload);
      this.$emit("close");
    },
  },
};
</script>
