export default {
  SET_INDIVIDUALS(state, individuals, totalIndividuals) {
    state.individuals = individuals;
    state.totalIndividuals = totalIndividuals;
  },
  SET_TOTAL_INDIVIDUALS(state, totalIndividuals) {
    state.totalIndividuals = totalIndividuals;
  },
  SET_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
};
