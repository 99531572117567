export default {
  serviceProvider(state) {
    return state.serviceProvider;
  },
  totalserviceProvider(state) {
    return state.totalserviceProvider;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
};
