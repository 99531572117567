<template>
  <div>
    <v-text-field
      flat="true"
      type="text"
      placeholder="Search"
      class="text-field"
      variant="solo"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: ["store", "action"],
  data() {
    return {
      searchQuery: "",
      timer: null,
    };
  },
  watch: {
    searchQuery: function (newQuery) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.fetchResults(newQuery);
      }, 1000);
    },
  },
  methods: {
    fetchResults(query) {
      const payload = { query, pageNumber: 1 };
      this.$store.dispatch(this.store + "/" + this.action, payload);
    },
  },
};
</script>
<style scoped>
.text-field {
  margin-top: 6%;
  width: 471px;
  border-radius: 8px;
  background: #fafbfc;
}

@media (min-width: 992px) {
  .text-field {
    margin-top: 7%;
    width: 471px;
    margin-left: 120%;
    background: #fafbfc;
  }
}
</style>
