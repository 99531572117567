export default {
  users(state) {
    return state.users;
  },
  totalUsers(state) {
    return state.totalUsers;
  },
  currentPage(state) {
    return state.currentPage;
  },
};
