import {
  getAttendeesForAnEvent,
  RemoveAttendeeFromAnEvent,
  getEventAttenddes,
} from "@/api/AtendeesApi";

export default {
  async fetchAttendeesForAnEvent(context, page) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    let payload = {
      eventId: context.state.currentEventId,
      page: page,
    };
    try {
      const response = await getAttendeesForAnEvent(payload);
      const atendees = response.data.content;
      const totalatendees = response.data.totalElements;
      context.commit("SET_ATTENDEES", atendees, totalatendees);
      context.commit("SET_TOTAL_ATTENDEES", totalatendees);
    } catch (e) {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Error getting atendees , try again !",
          color: "red",
        },
        { root: true }
      );
      if (this.$router) {
        this.$router.push({ name: "events" });
      }
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },

  async deleteAttendeeFromAnEvent(context, page) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    let payload = {
      eventId: context.getters.currentEventId,
      page: page,
    };
    try {
      await RemoveAttendeeFromAnEvent(payload);
      context.dispatch("fetchAttendeesForAnEvent", context.state.currentPage);
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Atendee deleted!",
          color: "success",
        },
        { root: true }
      );
    } catch (e) {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "Atendee deletion  failed !",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },

  async generateCsv(context, days) {
    context.commit("SHOW_PROGRESSBAR", true, { root: true });
    let payload = {
      eventId: context.getters.currentEventId,
      days: days,
    };
    try {
      const response = await getEventAttenddes(payload);
      context.commit("ATTENDEES_CSV", response.data);
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: `CSV generated successfully!`,
          color: "success",
        },
        { root: true }
      );
    } catch (e) {
      context.commit(
        "SHOW_SNACKBAR",
        {
          text: "CSV generation failed!",
          color: "red",
        },
        { root: true }
      );
    } finally {
      context.commit("HIDE_PROGRESSBAR", false, { root: true });
    }
  },
};
