<template>
  <v-data-table
    v-model:page="page"
    :headers="headers"
    :items="events"
    :items-per-page="itemsPerPage"
    @page-count="pageCount = $event"
  >
    <template v-slot:[`item.Attendees`]="{ item }">
      <v-icon @click="selectRow(item)" class="hover-blue"
        >mdi-account-group</v-icon
      >
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <EventsTableMenu :event="item" />
    </template>
    <template v-slot:bottom>
      <div class="footer">
        <PaginationTable
          :totalNumberofitems="totalevents"
          :itemsPerPage="itemsPerPage"
          :store="storeName"
          :action="actionName"
        />
        <!-- <div class="dropdown-buttons">
          <div>
            <div class="text-center filter-button">
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn
                    variant="outlined"
                    v-bind="props"
                    append-icon="mdi-chevron-up"
                  >
                    {{ selectedTitle }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="selectTitle(item.title)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="generate-csv">
            <v-btn color="#00AA8C"> Generate CSV </v-btn>
          </div>
          <div class="download-csv">
            <v-btn v-bind="props" color="#00AA8C"> Download CSV </v-btn>
          </div>
        </div> -->
      </div>
    </template>
  </v-data-table>
</template>

<script>
import EventsTableMenu from "./EventsTableMenu.vue";
import PaginationTable from "../shared/paginationTable.vue";
import { format } from "date-fns";

export default {
  name: "EventsTable",
  props: {
    events: {
      type: Array,
      required: true,
    },
    totalevents: {
      type: Number,
      required: true,
    },
  },
  components: {
    EventsTableMenu,
    PaginationTable,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 25,
    storeName: "events",
    actionName: "fetchEvents",
    selectedTitle: "Last 7 days",
    headers: [
      { title: "Event Name", value: "name" },
      { title: "Location", value: "location" },
      { title: "Country", value: "country" },
      { title: "City", value: "city" },
      { title: "Date", value: "startTime" },
      { title: "Venue", value: "venue" },
      { title: "View attendees", value: "Attendees" },
      { title: "Actions", value: "actions" },
    ],
    items: [
      { title: "Last 7 days" },
      { title: "Last 30 days" },
      { title: "Last 3 months" },
      { title: "All Time" },
    ],
    loading: true,
    totalItems: 0,
  }),
  filters: {
    niceDate(value) {
      return format(new Date(value), "MMM d");
    },
  },
  methods: {
    selectTitle(title) {
      this.selectedTitle = title;
    },
    selectRow(event) {
      this.$store.state.attendees.currentEventId = event.id;
      this.$store.state.attendees.currentEventName = event.name;
      this.$router.push({ name: "attendees" });
    },
  },
  computed: {},
};
</script>

<style scoped>
.footer {
  display: flex;
}
.dropdown {
  display: flex;
}
.dropdown-buttons {
  display: flex;
  margin-top: 0.5%;
  margin-left: 50%;
  width: 100%;
}
.filter-button {
  margin-left: 5%;
}
.generate-csv {
  margin-left: 5%;
}
.download-csv {
  margin-left: 5%;
  border-radius: 50px;
}
.hover-blue:hover {
  color: blue;
}
</style>
