import axios from "./config";

export function getAttendeesForAnEvent(payload) {
  return axios.get(
    `/events/${payload.eventId}/attendees?size=25&page=${payload.page}`
  );
}

export function RemoveAttendeeFromAnEvent(payload) {
  return axios.delete(`${payload.eventId}/events/${payload.page}/status`);
}

export function getEventAttenddes(payload) {
  return axios.get(`/attendees/csv?${payload.days}&event=${payload.eventId}`);
}
