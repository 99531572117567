<template>
  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card v-if="individual" class="ma-5 pa-5" width="700">
        <v-card-title>Personal details</v-card-title>
        <v-divider></v-divider>

        <v-row class="mt-4">
          <title-sub-title
            title="User names"
            :subtitle="individual.name"
          ></title-sub-title>
          <title-sub-title
            title="Email"
            :subtitle="individual.email"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            title="Country"
            :subtitle="individual.country"
          ></title-sub-title>
          <title-sub-title
            title="City"
            :subtitle="individual.city"
          ></title-sub-title>
        </v-row>
        <v-row>
          <title-sub-title
            v-if="individual.entity && individual.entity.name"
            title="Entity"
            :subtitle="individual.entity.name"
          ></title-sub-title>
          <title-sub-title
            v-if="individual.sector !== undefined && individual.sector !== null"
            title="Sector"
            :subtitle="individual.sector"
          ></title-sub-title>
          <title-sub-title
            title="Role"
            :subtitle="individual.role"
          ></title-sub-title>
        </v-row>
        <p class="ml-5 mt-5">Sectors</p>
        <v-row class="mt-4">
          <v-col>
            <div v-if="individual.sectors.length > 0">
              <v-row class="ml-5">
                <v-list-item-title
                  v-for="(item, index) in individual.sectors"
                  :key="index"
                  class="textlike mr-5"
                >
                  {{ item.sector.name }}
                </v-list-item-title>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <p class="ml-5 mt-5">Roles</p>
        <v-row class="mt-4">
          <v-col>
            <div v-if="individual.roles.length > 0">
              <v-row class="ml-5">
                <v-list-item-title
                  v-for="(item, index) in individual.roles"
                  :key="index"
                  class="textlike mr-5"
                >
                  {{ item.sectorsRoles.name }}
                </v-list-item-title>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-row class="mt-5">
            <v-col>
              <v-btn
                width="100%"
                class="me-2 p-5 text-none"
                color="#00AA8C"
                rounded
                prepend-icon="mdi-check"
                variant="flat"
                @click="updateUserKyc('verified')"
              >
                Verify
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                width="100%"
                class="text-none"
                prepend-icon="mdi-receipt-text-minus"
                variant="text"
                border
                rounded
                @click="updateUserKyc('unverified')"
              >
                Reject
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                @click="$emit('close')"
                width="100%"
                rounded
                border
                prepend-icon="mdi-close"
                dark
                text
              >
                Close</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TitleSubTitle from "@/components/shared/TitleSubTitle.vue";

export default {
  props: ["individual"],
  components: { TitleSubTitle },
  created() {
    this.dialog = true;
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    updateUserKyc(status) {
      let payload = {
        id: this.individual.id,
        values: {
          status: status,
        },
      };
      this.$store.dispatch("allUsers/updateUserKyc", payload);
      this.$emit("close");
    },
  },
};
</script>
<style></style>
