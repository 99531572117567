<template>
  <v-container>
    <div class="mx-4 mt-6">
      <div class="pageHeader">
        <div class="headerText">CANEX Registrations: FinancialInstiton</div>
      </div>
      <v-row>
        <v-col cols="4">
          <v-row>
            <v-col>
              <div class="stats">
                <div class="stats-text">
                  Financial <br />
                  Institutions
                </div>
                <div class="stats-number">{{ totalFinancialInstitutions }}</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-8 ma-2">
      <v-col>
        <FinancialInstitutionTable
          :financialInstitutions="receivedFinancialInstitutions"
          :totalfinancialInstitutions="totalFinancialInstitutions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FinancialInstitutionTable from "@/components/FinancialInstiton/FinancialInstitutionTable.vue";
export default {
  components: {
    FinancialInstitutionTable,
  },
  setup() {},
  created() {
    this.$store.dispatch("financialInstitutions/fetchFinacialInstituons", 0);
  },
  computed: {
    receivedFinancialInstitutions() {
      return this.$store.getters["financialInstitutions/financialInstitutions"];
    },
    totalFinancialInstitutions() {
      return this.$store.getters[
        "financialInstitutions/totalfinancialInstituions"
      ];
    },
  },
};
</script>
<style scoped>
.stats {
  width: 262px;
  height: 112px;
  margin-top: 3%;
  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
}
.stats-text {
  color: var(--black-100, #1c1c1c);
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10%;
  padding-top: 7%;
}
.stats-number {
  color: #000;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  padding-top: 7%;
  margin-left: 15%;
}
.headerText {
  padding-top: 1.8%;
  padding-left: 2%;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.pageHeader {
  background-image: url(../assets/pictures/events.png);
  background-size: cover;
  display: flex;
  width: 100%;
  height: 86px;
  margin-left: 0%;
}
</style>
