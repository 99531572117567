<template>
  <div>
    <metabaseDashbord />
  </div>
</template>

<script>
import metabaseDashbord from "@/components/overview/metabaseDashbord.vue";
export default {
  components: {
    metabaseDashbord,
  },
  setup() {},
};
</script>

<style scoped>
.text {
  color: aqua;
}
</style>
