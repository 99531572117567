export default {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_TOKEN(state, userToken) {
    state.userToken = userToken;
  },
  SHOW_PROGRESSBAR(state, show) {
    state.progressbar.show = show;
  },
  HIDE_PROGRESSBAR(state) {
    state.progressbar.show = false;
  },
  SHOW_SNACKBAR(state, values) {
    let timeout = 0;
    if (state.snackbar.show) {
      state.snackbar.show = false;
      timeout = 300;
    }
    setTimeout(() => {
      state.snackbar.show = true;
      state.snackbar.text = values.text;
      state.snackbar.color = values.color;
    }, timeout);
  },
  HIDE_SNACKBAR(state) {
    state.snackbar.show = false;
  },
};
